import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../components/Layout'
import slugify from '../utils/slugify'
import { LoadScript } from '@react-google-maps/api'
import * as s from '../components/listing/Listing.module.scss'
import { keyMergeListing } from '../utils/airtableKeyMerge'
import ListingBusinessInfo from '../components/listing/ListingBusinessInfo'


const lib = ["places"];
const idG = ["614f2b924c037cf7"];
const key = 'AIzaSyAkMp-DRCWbGCpLz9uqAUJq6-1sVPJBcQ0'; 
const Loading = (
  <div className={s.loading}>
    <p>Map Loading</p>
    <div className={s.loadingSpinner}>
    </div>
  </div>
);

const ListingBusinessTemplate = ({ data }) => {
  if (!data) return null
  const airtableContent = data.airtable || {}
  const airtable = airtableContent.data || {}
  
  
  const pageCommonData = data.prismicBusinessCommon || {}
  const pageCommon = pageCommonData.data || {}

  const slug = slugify(airtable.Name)
  const lang = pageCommonData.lang 
  const url = `businesses/${slug}`

  const keyMergedAirtable = keyMergeListing(airtableContent.table, airtable, lang.slice(0,2).toLowerCase());
  const type = 'listing'
  const lowerLang = lang.toLowerCase()
  const alternateLanguages = []
  
  pageCommonData.alternate_languages.map(alt => alternateLanguages.push({
    type : type,
    lang : alt.lang,
    uid : slug,
    table: 'businesses'
  }))
  const menu = data.prismicMenu || {}
  const title = `${airtable.Name} | businesses | Whose.Land`
 
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  return (
      <Layout 
        menu={menu.data} 
        activeDocMeta={activeDoc} 
        title={title}
        description={null}
      >
       <LoadScript 
          googleMapsApiKey={key} 
          libraries={lib} 
          mapIds={idG} 
          loadingElement={Loading} 
          language={lowerLang.slice(0,2)}
        >
          <ListingBusinessInfo
            data={keyMergedAirtable}
            labels={pageCommon}
            lang={lang}
          />
        </LoadScript> 
        
      </Layout>
  )
}

export const query = graphql`
  query listingBusinessQuery($id: String, $lang: String) {
    airtable(id: { eq: $id}) {
      id
      table
      data {
        Slug
        Name
        Description
        E_mail_Address
        Product_Categories
        CCAB_Profile_Link
        Phone_Number
        Web_Site
        Keywords
        Combined_Address
        Longitude
        Latitude
        Logo {
          localFiles {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        Community {
          data {
            Name
          }
        }
      }
      
    }
    prismicBusinessCommon(lang: { eq: $lang}) {
      lang
      alternate_languages {
        uid
        type
        lang
      }
      data {
        category_label
        ccab_image {
          gatsbyImageData
          alt
        }
        ccab_btn_label
        ccab_label
        community_label
        description_label
        details_label
        photo_gallery_label
        photo_gallery_description {
          richText
        }
        socials_label
        tags_label
      }
    }
    prismicMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
      ...BottomMenuFragment
    }
  }
`

export default withPrismicPreview(ListingBusinessTemplate);
