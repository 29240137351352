import React from 'react';
import * as s from './Listing.module.scss';
import { Container } from '../Container';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  GoogleMap,
  Polygon,
  Marker,
} from "@react-google-maps/api";
import { PrismicLink } from '@prismicio/react';
import slugify from '../../utils/slugify';
import { isFilled } from '@prismicio/helpers';
import { FaGlobe, FaMapMarkerAlt, FaPhone } from 'react-icons/fa';

const map_height = '540px'

const ListingBusinessInfo = ({data, labels}) => {
  if(!data) return <></>;
  const [map, setMap] = React.useState(null);
  const onLoad = React.useCallback(function callback(gmap) {
    setMap(gmap);
  },[]);
  const onUnmount = React.useCallback(function callback(gmap) {
    setMap(null)
  }, []);
  return (
    <section className={s.ListingContainer}>
      <Container>
        <div className={s.BusinessContent} style={{marginTop: 32, marginBottom: 40}}>
          {
            data.logo?.childImageSharp &&
              <GatsbyImage 
                image={data.logo.childImageSharp?.gatsbyImageData} 
                imgStyle={{objectFit: 'contain', objectPosition: 'left center'}}
                alt=""
              />                    
          } 
        </div>
        <div className={s.Content_Wide} style={{display: 'flex', flexDirection: 'column', gap: 32, marginBottom: 64}}>
          <h1 style={{marginBottom: 0}}>{data.name}</h1>
          
          {(data.categories || data.communities) && 

            <div>
              {data.categories && <p><strong>{labels.category_label}: </strong> {data.categories}</p>}
              {data.communities?.length > 0 && <p><strong>{labels.community_label}: </strong><PrismicLink style={{color: 'inherit', textDecoration: 'underline'}} href={`../communities/${slugify(data.communities[0].data?.Name)}`} target="_self">{data.communities[0].data?.Name}</PrismicLink> </p>}
            </div>
          }
          
          {data.description && 
            <div>
              <h2 style={{marginTop: 0}}>{labels.description_label}</h2>
              <p>{data.description}</p>
            </div>
          }
        </div>

        <div className={s.FlexWrap} style={{marginBottom: 64
        }}>
          <div className={s.Content} >
            <h2 style={{marginBottom: 32}}>{labels.details_label}</h2>
            {data.web_link && <div className={s.ContactDetail}><FaGlobe/><p><PrismicLink className={s.MoreBtn} href={data.web_link}>{data.web_link}</PrismicLink></p></div>}
            {data.phone && <div className={s.ContactDetail}><FaPhone/> <p>{data.phone}</p></div>}
            {data.address && <div className={s.ContactDetail}><FaMapMarkerAlt/> <p>{data.address}</p></div>}
          </div>
          <div className={s.Content}>
            <GoogleMap
                
                options={{ mapId: "68283565ee8dd767", disableDefaultUI: true, fullscreenControl: true, zoomControl: true, keyboardShortcuts: false }}
                mapContainerStyle={{ height: map_height}}
                onLoad={onLoad}
                onUnmount={onUnmount}
                center={data.coordinates}
                zoom={7}
              >
                <Marker
                  position={data.coordinates}
                /> 
              </GoogleMap>
          </div>
          

        </div>

       {data.ccab_link && <div className={s.Content} style={{marginBottom: 64}}>
     
          <h2 style={{marginBottom: 32}}>{labels.ccab_label}</h2>
          <div>
            {labels.ccab_image &&
              <GatsbyImage
                image={labels.ccab_image?.gatsbyImageData}
                alt={labels.ccab_image?.alt || labels.ccab_label}
                style={{marginBottom: 32}}
              />
            }
          </div>
          <div style={{maxWidth: 'fit-content'}}>
            <PrismicLink href={data.ccab_link} className={s.WhiteBtn} target="_blank" rel="noopener noreferrer">{labels.ccab_btn_label}</PrismicLink>
          </div>
          

        </div>
      }
      </Container>

     
    </section>
  );
};

export default ListingBusinessInfo;
